<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div  > 
    <div style="height:90vh">
      <div
        class="vx-row justify-center items-center ">
        <div  v-if="false" class="vx-col lg:w-1/2   customBG hidden  lg:block">
          <div class="logo mt-10 lg:w-3/4">
          <img width="100" height="100" class="TextLogo" alt="" src="@/assets/images/logo.png" />
            <!-- <h3 class="TextLogo ">
               Doctor <span style="color: primary">4</span> Me
            </h3> -->
          </div>
          <p
            style="
              text-align: center;
              font: normal normal 100 20px/51px Futura PT;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            "
          >
            {{ $t("Create New ") }}
          </p>

          <p
            style="
              text-align: center;
              font: normal normal 100 20px/51px Futura PT;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            "
          >
            {{ $t("Doctor Profile ") }}
          </p>
        </div>

        <div
          class="vx-col lg:w-1/2 d-theme-dark-bg "
          style="border-radius:15px"
        >
          <div class="pt-4">
            <div class="MyPopClose">
              <feather-icon
                icon="XIcon"
                svgClasses="w-10 h-10 hover:text-primary stroke-current"
                @click.stop="$emit('closePop')"
              />
            </div>
            <div style="text-align: center"  >
              <div class="vx-row w-full con-img justify-center">
                <img
                  v-if="doctorModel.ProfileImagePath"
                  :src="baseURL + doctorModel.ProfileImagePath"
                  alt="user-img"
                  width="80"
                  height="80"
                  class="rounded-full shadow-md cursor-pointer block m-4"
                />

                <img
                  v-else
                  src="@/assets/images/user.png"
                  alt="user-img"
                  width="80"
                  height="80"
                  class="rounded-full shadow-md cursor-pointer block m-4"
                />
                <div class="position: absolute py-12 w-1">
                  <input
                    type="file"
                    class="hidden"
                    ref="uploadImgInput"
                    @change="updateCurrImg"
                    accept="image/*"
                    @on-success="successUpload"
                  />

                  <vs-avatar
                    @click="$refs.uploadImgInput.click()"
                    color="#004477"
                    icon-pack="feather"
                    icon="icon-upload"
                    class="m-5"
                  />
                </div>
              </div>
            </div>

            <div>
              <vs-input
                data-vv-validate-on="blur"
                name="name"
                icon-pack="feather"
                class="vx-col mt-3 w-full"
                :placeholder="$t('FullName')"
                v-model="doctorModel.NameEN"
                :icon-after="true"
              />
            </div>
              <div >
             <vs-input
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    name="email"
                    type="email"
                    :placeholder="$t('Email')"
                    v-model="doctorModel.Email"
                    class="vx-col mt-3 w-full"
                  />
            </div>
            <span class="warning">after save please check mail to complete data</span>

              <div>
              <vs-input
                  v-validate="'required|min:5'"
                  data-vv-validate-on="blur"
                  :placeholder="$t('UserName')"
                  v-model="doctorModel.UserName"
                  class="vx-col mt-3 w-full"
                />
            </div>

             

               <div    > 
            <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:8|max:20'"
      name="password"
       @input="checkPassword"
       :placeholder="$t('Password')"
      v-model="doctorModel.Password"
      class="vx-col mt-3 w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>
      <ul class="vx-col">
          <li v-bind:class="{ is_valid: contains_eight_characters }">
            8 {{$t("Characters")}}
          </li>
          <li v-bind:class="{ is_valid: contains_number }">{{$t("ContainsNumber")}}</li>
          <li v-bind:class="{ is_valid: contains_uppercase }"> </li>            
          {{$t("ContainsUppercase")}} 
          <li v-bind:class="{ is_valid: contains_lowercase }">
          {{$t("ContainsLowercase")}} 

          </li>
          <li v-bind:class="{ is_valid: contains_special_character }">
           {{$t("ContainsSpecialCharacter")}} 
          </li>
      </ul>

 </div>

            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="SaveDoctor()"
                :disabled="!validateForm||!valid_password"
                >{{ $t("Save") }}</vs-button
              >
            </div>
          </div>
          <div class="p-10"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";

export default {
  data() {
    return {
       contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase:false,
      contains_special_character: false,
       valid_password:false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      doctorModel: { DoctorSpecialities: [], ProfileImagePath: "" ,NameEN :"",UserName:""},
    };
  },
  props: {
    searchDoctorModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    //LoginFirebase,
    //LoginAuth0
  },
  methods: {
    SaveDoctor() {
      debugger;
      var speciality = {};
      speciality.SpecialtyID = this.searchDoctorModel.SpecialtyID;
      this.doctorModel.DoctorSpecialities = [];
      this.doctorModel.DoctorSpecialities.push(speciality);
      this.doctorModel.CountryID = this.searchDoctorModel.CountryID;
      this.doctorModel.UserType ='Doctor';
      this.$vs.loading();
     this.doctorModel.IsGeneralDoctor=false;
      this.$store
        .dispatch("DoctorList/AddUserDoctor", this.doctorModel)
        .then((res) => {
          debugger
          if (res.status == 200) {
            window.showSuccess();
            this.doctorModel = {};
            this.$vs.loading.close();
            this.$store.dispatch(
              "DoctorList/SearchDoctors",
              this.searchDoctorModel
            );
            this.$emit("closePop");
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          debugger
          this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Error"),
        text: err.response.data.Message
      });
          this.$vs.loading.close();
        });
    },
    checkPassword() {
      this.password_length = this.doctorModel.Password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.doctorModel.Password);
      this.contains_uppercase = /[A-Z]/.test(this.doctorModel.Password);
      this.contains_lowercase = /[a-z]/.test(this.doctorModel.Password);
      this.contains_special_character = format.test(this.doctorModel.Password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_lowercase ===true&&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    successUpload() {
      debugger;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          debugger;
          this.doctorModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    searchDoctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
    validateForm() {
      debugger
      return (
        this.doctorModel.NameEN != "" &&
        this.doctorModel.Email != "" &&
        this.doctorModel.Password != "" &&
        this.doctorModel.UserName!=""&&
        this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_lowercase&&
          this.contains_special_character
      );
  },
  },
  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
  },
};
</script>
<style >
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss">
.customBG{
   background: transparent
            radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%)
            0% 0% no-repeat padding-box;
          box-shadow: -3px 0px 22px #00000029;
          border-radius: 31px 31px 31px 31px;
          opacity: 1;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin: auto;
  padding: 1rem !important;
  text-align: center;
  left: 52%;
  top: -13rem;
  width: 55% !important;

  position: relative;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
}
.MyForm {
  width:100%;
  margin: auto;
}
</style>
